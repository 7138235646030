import axios from "axios"

export default {
    namespaced:true,
    state: {
        rides: [],
        
      },
    
      actions: {
    

        getRides({commit}, {id, userType}) {
            axios.get('rides-by-users/' + id, {
                params: { userType: userType },
                headers: {
                  'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
                }
              }).then((res) => {
                console.log(res)
                commit('setRides',res.data.rides)
              }).catch((err) => {
                console.log(err)
              })
        },
    
      },
    
      mutations : {
    
        setRides(state, value) {
          state.rides = value
          
        },
     
      },
    
      getters : {
        ridesGetter(state) {
          return state.rides
        },
      }
}