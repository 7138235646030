import axios from "axios"

export default {
    namespaced:true,
    state: {
        standardRides: [],
        requireStandardRides:[]
      },
    
      actions: {
    

        getStandardRides({commit}) {
          axios.get('read-rides',{params:{ride_type:'standard'},
          headers:{
            'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
          }
        }).then((response) => {
            commit('setStandardRides', response.data.rides)
            return response
          }).catch((error) => {
            console.log(error)
          })
        },

        getStandardRidesForDetail({commit}) {
          axios.get('read-rides',{params:{ride_type:'standard'},
          headers:{
            'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
          }
        }).then((response) => {
            commit('setStandardRidesforDetail', response.data.rides)
            return response
          }).catch((error) => {
            console.log(error)
          })
        },
    
        removeDriver({commit},id){
          axios.post('dismiss-driver/'+id,{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then(()=>{
            commit('removeDriver',id)
          }).catch((err)=>{
            console.log(err)
          })
    
        },
    
        assignDriver({commit},payload){
          return new Promise((resolve,reject)=>{
            axios.post('assign-driver/'+payload.ride._id,{new_driver_number: payload.newNumber,vehicle_id: payload.vehicle_id},{
              headers:{
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
              }
            }).then((response)=>{
              if(response) {
                commit('assignDriver', {newNumber: payload.newNumber, ride_id: payload.ride._id})
                resolve(response)
              }
            }).catch((error)=>{
              console.log(error);
              reject(error)
            })
          })
    
        },

        setRequiredStandardRides({commit}, value){
          commit('setRequiredStandardData', value)
        }
    
    
      },
    
      mutations : {
    
        setStandardRides(state, value) {
          state.standardRides = value
          state.requireStandardRides = value
        },

        setStandardRidesforDetail(state, value) {
          state.standardRides = value
        },

        setRequiredStandardData(state, value){
          if (value === 'reset'){
            state.requireStandardRides = state.standardRides
          }
          else{
            state.requireStandardRides = state.standardRides.filter(data => data.status === value)
          }
        },
        removeDriver(state,value){
          try {
            let index = state.standardRides.findIndex(ride => ride._id === value)
            state.standardRides[index].driver_id = null
          } catch (error){
            console.log(error)
          }
        },
    
        assignDriver(state,payload){
          let index= state.standardRides.findIndex(ride => ride._id === payload.ride_id)
          state.standardRides[index].driver_number=payload.newNumber
        }
      },
    
      getters : {
        standardRidesGetter(state) {
          return state.requireStandardRides
        },

        standardRideGetter:(state) => (id) =>{
          return state.standardRides.find( ride => ride._id === id)
        }
      }
}