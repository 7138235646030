import axios from "axios";


export default {
    namespaced:true,
    state: {
        payments: []
      },
      actions: {
        getPayments({ commit }) {
          axios.get('all-payments',{
            headers:{
              'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('user')).token
            }
          }).then((response) => {
            
            commit('setPayments', response.data.payments)
          }).catch((error) => {
            console.log(error)
          })
        }
      },
    
      mutations: {
        setPayments(state, value) {
          state.payments = value
        }
      },
    
      getters: {
        paymentsGetter(state) {
          return state.payments
        },

        paymentGetter: (state) => (id) => {
          
          return state.payments.find(payment => payment._id === id)
        }
      }
}