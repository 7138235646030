import Vue from 'vue';
import Vuex from 'vuex';
import driver from './modules/driver';
import vehicles from './modules/vehicles';
import registrations from './modules/registrations';
import riders from './modules/riders';
import hourlyRides from './modules/hourlyRides';
import premiumRides from './modules/premiumRides';
import transactions from './modules/transactions';
import auth from './modules/auth'
import users from './modules/users';
import userRides from './modules/userRides';
import emails from './modules/emails';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
   driver,
   vehicles,
   registrations,
   riders,
   hourlyRides,
   premiumRides,
   transactions,
   auth,
   users,
   userRides,
   emails
    // Add more modules here if needed
  }
});
