export default [
    {
        path: '/',
        name: 'Home',       
        component: () => import('../views/pages/dashboard')
    },
    {
        path:'/account/profile',
        name:'profile',
        component:() => import('../views/pages/account/profile')
    },
    {
        path:'/account/login',
        name:'login',
        component:() => import('../views/pages/account/login')
    },
    // {
    //     path:'/account/register',
    //     name:'Rigister',
    //     component:() => import('../views/pages/account/register')
    // },
    {
        path:'/create/users',
        name:'create-User',
        component:() => import('../views/pages/account/create-users')
    },
    {
        path:'/all/users',
        name:'all-users',
        component:() => import('../views/pages/account/all-users')
    },
   
    

    {
        path: '/email/inbox',
        name: 'Inbox',
        component: () => import('../views/pages/email/inbox')
    },
    {
        path: '/email/starred',
        name: 'Starred',
        component: () => import('../views/pages/email/starred')
    },
    {
        path: '/email/sent_mail',
        name: 'Sent Mail',
        component: () => import('../views/pages/email/sent_mail')
    },
    {
        path: '/email/replies',
        name: 'Replies',
        component: () => import('../views/pages/email/replies')
    },
    {
        path: '/email/trash',
        name: 'Trash',
        component: () => import('../views/pages/email/trash')
    },
    {
        path: '/email/reademail/:id/:text',
        name: 'Read Email',
        component: () => import('../views/pages/email/reademail')
    },
    {
        path: '/email/readSms/:id',
        name: 'Read Sms',
        component: () => import('../views/pages/email/readSms')
    },
    {
        path: '/chat',
        name:'Chat',
        component:()=> import('../views/pages/chat')
    },
    {
        path: '/drivers',
        name: 'drivers',
        component: () => import('../views/pages/drivers/index'),
        meta:{ 
            
            keepAlive:true,
         },
         beforeRouteLeave(to, from, next) {
            if (from.meta.keepAlive) {
              // Save the component's data when leaving the route
              from.meta.filter = this.filter;
              from.meta.currentPage=this.currentPage
              from.meta.perPage=this.perPage
            }
            next();
          },
          beforeRouteEnter(to, from, next) {
            if (to.meta.keepAlive && from.meta.componentData) {
              // Restore the component's data when entering the route
              next(vm => {
                vm.filter = from.meta.filter;
                vm.currentPage=from.meta.currentPage
                vm.perPage=from.meta.perPage
              });
            } else {
              next();
            }
          },
    },
    {
        path: '/drivers/:id',
        name: 'Driver Details',
        component: () => import('../views/pages/drivers/details'),
        
    },
    {
        path: '/drivers/document/:id',
        name: 'Driver Documents',
        component: () => import('../views/pages/drivers/documents')
    },
    
   
   
    {
        path: '/drivers/driver-rides/:id',
        name: 'Driver Rides',
        component: () => import('../views/pages/drivers/rides')
    },
    {
        path: '/riders',
        name: 'Riders',
        component: () => import('../views/pages/riders/index'),
        meta:{ 
            
            keepAlive:true,
         },
         beforeRouteLeave(to, from, next) {
            if (from.meta.keepAlive) {
              // Save the component's data when leaving the route
              from.meta.filter = this.filter;
              from.meta.currentPage=this.currentPage
              from.meta.perPage=this.perPage
            }
            next();
          },
          beforeRouteEnter(to, from, next) {
            if (to.meta.keepAlive && from.meta.componentData) {
              // Restore the component's data when entering the route
              next(vm => {
                vm.filter = from.meta.filter;
                vm.currentPage=from.meta.currentPage
                vm.perPage=from.meta.perPage
              });
            } else {
              next();
            }
          },
    },

    {
        path: '/riders/rider-rides/:id',
        name: 'Riders Rides',
        component: () => import('../views/pages/riders/rides')
    },

    {
        path: '/riders/:id',
        name: 'Riders Details',
        component: () => import('../views/pages/riders/details')
    },

   
    {
        path: '/rides/standard',
        name: 'Standard Rides',
        component: () => import('../views/pages/rides/standard'),
        meta:{ 
            
            keepAlive:true,
         },
         beforeRouteLeave(to, from, next) {
            if (from.meta.keepAlive) {
              // Save the component's data when leaving the route
              from.meta.filter = this.filter;
              from.meta.currentPage=this.currentPage
              from.meta.perPage=this.perPage
            }
            next();
          },
          beforeRouteEnter(to, from, next) {
            if (to.meta.keepAlive && from.meta.componentData) {
              // Restore the component's data when entering the route
              next(vm => {
                vm.filter = from.meta.filter;
                vm.currentPage=from.meta.currentPage
                vm.perPage=from.meta.perPage
              });
            } else {
              next();
            }
          },
    },
    {
        path: '/rides/premium',
        name: 'Premium Rides',
        component: () => import('../views/pages/rides/premium'),
        meta:{ 
            
            keepAlive:true,
         },
         beforeRouteLeave(to, from, next) {
            if (from.meta.keepAlive) {
              // Save the component's data when leaving the route
              from.meta.filter = this.filter;
              from.meta.currentPage=this.currentPage
              from.meta.perPage=this.perPage
            }
            next();
          },
          beforeRouteEnter(to, from, next) {
            if (to.meta.keepAlive && from.meta.componentData) {
              // Restore the component's data when entering the route
              next(vm => {
                vm.filter = from.meta.filter;
                vm.currentPage=from.meta.currentPage
                vm.perPage=from.meta.perPage
              });
            } else {
              next();
            }
          },
    },
    {
        path: '/rides/standardDetails/:id',
        name: 'Standard Ride Details',
        component: () => import('../views/pages/rides/standardDetails')
    },
    {
        path: '/rides/premiumDetails/:id',
        name: 'Premium Ride Details',
        component: () => import('../views/pages/rides/premiumDetails')
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: () => import('../views/pages/transactions/index'),
        meta:{ 
            
            keepAlive:true,
         },
         beforeRouteLeave(to, from, next) {
            if (from.meta.keepAlive) {
              // Save the component's data when leaving the route
              from.meta.filter = this.filter;
              from.meta.currentPage=this.currentPage
              from.meta.perPage=this.perPage
            }
            next();
          },
          beforeRouteEnter(to, from, next) {
            if (to.meta.keepAlive && from.meta.componentData) {
              // Restore the component's data when entering the route
              next(vm => {
                vm.filter = from.meta.filter;
                vm.currentPage=from.meta.currentPage
                vm.perPage=from.meta.perPage
              });
            } else {
              next();
            }
          },
    },
    {
        path: '/transactions/detail/:id',
        name: 'Transactions Details',
        component: () => import('../views/pages/transactions/detail')
    },
    {
        path: "*",
        component: () => import('../views/pages/error/404')
    }
]
