import axios from "axios";

export default {
    namespaced:true,
    state: {
        drivers: [],
        requireDrivers: []
      },
    
      actions: {
    
        getDrivers({commit}) {
          axios.get('read-drivers',{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then((response) => {
            commit('setDrivers', response.data.drivers)
          }).catch((error) => {
            console.log(error)
          })
        
        
        },

        deleteDriver({commit},id){
          axios.post('delete-driver/'+id, null, {
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then(() => {
            commit('deleteDriver', id)
          }).catch((err) => {
            console.log(err)
          })
        },

        getDriversforDetail({commit}){
          axios.get('read-drivers',{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then((response) => {
            commit('setDriversForDetail', response.data.drivers)
          }).catch((error) => {
            console.log(error)
          })
        },

        setRequiredDrivers({commit}, value){
            commit('setRequiredDriversData', value)
        },

        addDocumentExpiry({commit}, {id, driver}){
          console.log('add doc action',id,driver)
          commit('addDocumentExpiry', { id: id, driver: driver })
        }
      },
    
      mutations : {
        setDrivers(state, value) {
          state.drivers = value
          state.requireDrivers = value
        },

        setDriversForDetail(state, value) {
          state.drivers = value
        },

        setRequiredDriversData(state, value){
          if (value === 'reset'){
            state.requireDrivers = state.drivers
          }
          else{
            state.requireDrivers = state.drivers.filter(data => data.status.account_status === value.toLowerCase())
          }
        },

        deleteDriver( state, id ){
          let index = state.requireDrivers.findIndex( driver => driver._id === id)
          state.requireDrivers.splice( index, 1 )
          index = state.drivers.findIndex( driver => driver._id === id)
          state.drivers.splice( index, 1 )
        },

        addDocumentExpiry(state, {id, driver} ){
          let index = state.drivers.findIndex( driver => driver._id === id)
          console.log('mutate',id, driver,state.drivers[index])
          state.drivers[index] = driver
          console.log(state.drivers[index])
        }

      },
      getters : {
        driversGetter(state) {
          return state.requireDrivers
        },

        driverGetter:(state) => (id) => {
          return state.drivers.find(driver => driver._id === id)
        }
      }
}