import axios from "axios";

export default {
    namespaced:true,
    state: {
        users:[]
      },
    
      actions: {
    
        getUsers({commit}) {
          axios.get('read-admin-users',{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then((response) => {
            commit('setUsers', response.data.users)
          }).catch((error) => {
            console.log(error)
          })
        
        
        },
       
      },
    
      mutations : {
        setUsers(state, value) {
          state.users = value
        },
    },
        
      getters : {
        getUsers(state) {
          return state.users
        },
        getUser:(state) => (id) =>{
          return state.users.find(user => user._id === id)
        }
      }
}