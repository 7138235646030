import AuthService from '../../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export default {
  namespaced: true,
  state: initialState,
  actions: {

    login({ commit }, user) {
      return AuthService.login(user).then((user) => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        }).catch((error) => {
            commit('loginFailure');
            return Promise.reject(error);
          })
    },

    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },

    register({ commit }, user) {
      return AuthService.register(user).then((response) => {
          commit('registerSuccess');
          return Promise.resolve(response.data);

        }).catch((error) => {
          
          commit('registerFailure');
          return Promise.reject(error);
        })
        
    
    }
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },

    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

    
  },

  getters:{
    getUser(state){
      return state.user.user
    }
  }
};