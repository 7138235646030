import axios from "axios"

export default {
    namespaced:true,
    state: {
        riders: []
      },
    
      actions: {
    
        getRiders({commit}) {
          axios.get('read-Riders',{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then((response) => {
            commit('setRiders', response.data.riders)
            return response
          }).catch((error) => {
            console.log(error)
          })
        },
    
      },
    
      mutations : {
    
        setRiders(state, value) {
          state.riders = value
        },
      },
    
      getters : {
    
        ridersGetter(state) {
          return state.riders
        },

        riderGetter:(state) => (id) =>{
          return state.riders.find(rider => rider._id === id)
        }
      }
}