import axios from "axios"

export default {
    namespaced:true,
    state:{
        vehicles:[]
      },
    
      actions: {
    
        getVehicles({commit},id){
          axios.get('read-vehicles',{params:{driver_id:id},
          headers:{
            'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
          }}).then((res)=>{
        
            commit('setVehicles',res.data.vehicles)
        
          }).catch((err)=>{
            console.log(err)
          })
        },
        verifyVehicle({commit},id){
          commit('vehicleVerify',id)
        },
        rejectVehicle({commit},id){
          commit('vehicleReject',id)
        },
    
      },
      mutations:{
    
        setVehicles(state,value){
          state.vehicles=value
        },
    
        vehicleVerify(state,id){
          let index = state.vehicles.findIndex(vehicle=>vehicle._id===id)
          state.vehicles[index].registration_status= 'verified'
        },
        
        vehicleReject(state,id){
          let index = state.vehicles.findIndex(vehicle=>vehicle._id===id)
          state.vehicles[index].registration_status= 'not-uploaded'
        }
      },
      getters:{
        vehiclesGetter(state){
          return state.vehicles
        }
      }
}