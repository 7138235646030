<template>
  <div id="app">
    <keep-alive >
      <router-view></router-view>
    </keep-alive>
     
  </div>
</template>

<script>
import appConfig from "@/app.config";


export default {
  name: "app",
  page: {
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },

  // computed:{
  //   keepAliveComponents() {
  //     return this.$route.matched
  //       .filter(route => route.meta.keepAlive)
  //       .map(route => route.components.default.name)
  //   }
  // }
};
</script>