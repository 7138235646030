import Vue from 'vue'
import App from './App.vue'
import vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import vco from "v-click-outside"
import router from './router'
import jquery from 'jquery'
import axios from "axios"
import i18n from './i18n'
import "@/assets/scss/app.scss"
import store from "./store"
import JsonExcel from "vue-json-excel";

axios.defaults.headers.common['x-api-key']='Wx8Cc7rEDJXTcyPfzM65h5q0AKDjLXqb';
axios.defaults.baseURL='https://dev-live.rydelinx.com/api/admin/user/'

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(jquery)
Vue.use(vco)
Vue.use(axios)
Vue.use(Vuelidate)
Vue.use(vuex)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBCyoW9uCqpjPpzSlsLC2kzETOF2993vBs',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  i18n,
  render: h => h(App),
  store,
}).$mount('#app')
